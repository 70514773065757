<template src="../templates/FormQuestion.html"></template>
<script>

import extractEnvironmentValuesMixin from '@/mixins/extractEnvironmentValuesMixin';
import Modal from '@c/Modal';
import List from '@c/List';
import FormInput from '@f/Input';
import FormButton from '@f/Button';
import FormSelect from '@f/Select';
import Multiselect from '@vueform/multiselect';
import FormCheckbox from '@f/Check';
import FormRadio from '@f/Radio';
import FormTextarea from '@f/Textarea';
import Editor from '@tinymce/tinymce-vue';
import questionTemplate from '../templates/questionTemplate';
import helpers from '../../../tools/helpers';

export default {
    name: 'FormQuestion',
    components: {
        Modal,
        FormInput,
        FormButton,
        FormSelect,
        Multiselect,
        FormTextarea,
        FormCheckbox,
        FormRadio,
        List,
        Editor,
    },
    mixins: [
        extractEnvironmentValuesMixin,
    ],
    props: {
        question: {
            type: Object,
            default() {
                return {};
            },
        },
        showModal: {
            type: Boolean,
            default() {
                return false;
            }
        },
        onClose: {
            type: Function,
        },
        onOk: {
            type: Function,
        },
        getAnswers: {
            type: Function,
        },
    },
    data() {
        return {
            isAnswersLoading: false,
        };

    },
    computed: {
        q() {
            return this.question;
        },
        questionCodes() {
            const options = this.question.details.question_codes_1c.map((el) => ({label: el, value: el}));
            return [{value: '', label: 'Не выбран'}, ...options];
        },
        answerCodes() {
            const options = this.question.details.answer_codes_1c.map((el) => ({title: el, value: el}));
            return [...options];
        }
    },
    methods: {
        debugForm() {
            console.log(this.q);
            console.log(Object.fromEntries(new FormData(document.getElementById('question-edit'))));
        },
        remove(index) {
            this.q.answers = this.q.answers.filter((el, i) => i !== index);
        },
        add() {
            this.q.answers.push(helpers.deepCopy(questionTemplate));
        },
        async handle1CCodeChange(code) {
            this.isAnswersLoading = true;
            this.q.answers = [];
            if (code !== '') {
                this.q.answers = await this.getAnswers(code);
            }
            this.isAnswersLoading = false;
        }
    },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
