<template src="./templates/questions.html"></template>
<script>

import FormInput from '@f/Input';
import FormCheckbox from '@f/Check';
import FormButton from '@f/Button';
import PageTitle from '@c/PageTitle';
import Breadcrumbs from '@c/Breadcrumbs';
import PageHeader from '@c/Header';
import Grid from '@c/Grid';
import Confirm from '@c/Confirm';
import {
    delQuestion,
    getQuestion,
    getAnswersBy1CCode,
    getQuestions1CCodes,
    getQuestionCategories,
    getQuestionnaire,
    getQuestions,
    setQuestion,
    exportQuestionsToQuestionnaires
} from '@api/questionnaire';
import NotFound from '../../components/NotFound';
import helpers from '../../tools/helpers';
import FormQuestion from './components/FormQuestion';
import ExportQuestionsForm from './components/ExportQuestionsForm';
import questionTemplate, {QUESTION_TYPE_ANSWER} from './templates/questionTemplate';

export default {
    name: 'QuestionnaireQuestions',
    components: {
        PageTitle,
        PageHeader,
        Breadcrumbs,
        Grid,
        FormQuestion,
        FormButton,
        FormInput,
        FormCheckbox,
        NotFound,
        ExportQuestionsForm,
        Confirm,
    },
    props: {
        id: {
            type: String,
            default() {
                return null;
            }
        },
        // eslint-disable-next-line vue/prop-name-casing
        subject_id: {
            type: String,
            default() {
                return null;
            }
        },
        action: {
            type: String,
            default() {
                return null;
            }
        },
    },
    data() {
        return {
            showQuestionModal: false,
            questions: [],
            questionsCaption: '',
            questionsCode: '',
            editedQuestion: helpers.deepCopy(questionTemplate),
            
            showExportModal: false,
            selectedQuestions: [],
            selectedCategories: [],
            
            showRemoveQuestionModal: false,
            removeQuestionButtons: [],
        };
    },
    computed: {
        questionnaireId() {
            return this.$route.params.id;
        }
    },
    created() {
        this.initQuestions();
        this.getCaptionAndCode();
    },
    methods: {
        async getCaptionAndCode() {
            const questionnaire = await getQuestionnaire(this.questionnaireId);
            this.questionsCaption = questionnaire?.name;
            this.questionsCode = questionnaire?.code;
        },
        async initQuestions() {
            const questions = await getQuestions(this.$route.params.id);
            if (questions.length) {
                this.questions = questions.map(item => (
                    {...item, selection: (item.question_code ? '+' : '-'), published: (item.published ? '+' : '-')}
                ));
            }
        },
        async editQuestion(id) {
            if (id !== null) {
                this.editedQuestion = await getQuestion(id);
            } else {
                this.editedQuestion = helpers.deepCopy(questionTemplate);
                this.editedQuestion.details.question_categories = await getQuestionCategories();
                this.editedQuestion.details.question_codes_1c = await getQuestions1CCodes(this.questionnaireId);
                this.editedQuestion.details.answer_codes_1c = await getQuestions1CCodes(
                    this.questionnaireId,
                    QUESTION_TYPE_ANSWER
                );
            }

            this.showQuestionModal = true;
        },
        async getAnswersBy1CCode(code1C) {
            return getAnswersBy1CCode(code1C);
        },
        async saveQuestion() {
            await setQuestion({
                question: this.editedQuestion,
                questionnaire_id: this.questionnaireId
            });
            await this.initQuestions();
            this.showQuestionModal = false;
        },
        async removeQuestionHandler(id) {
            this.updateRemoveButtons(id);
            this.showRemoveQuestionModal = true;
        },
        /**
         * @param id
         * @param removeType one|all: on - для текущего опроса, all - для всех
         * @returns {Promise<void>}
         */
        async removeQuestion(id, removeType = 'one') {
            await delQuestion(id, this.questionnaireId, removeType);
            await this.initQuestions();
            this.showRemoveQuestionModal = false;
        },
        hideQuestionModal() {
            this.showQuestionModal = false;
        },
        selectAll() {
            if (this.questions.length !== this.selectedQuestions.length) {
                this.selectedQuestions = [];
                this.questions.forEach(i => this.selectedQuestions.push(i.id));
            } else {
                this.selectedQuestions = [];
            }
        },
        showExportWindow() {
            this.showExportModal = true;
        },
        exportQuestions() {
            exportQuestionsToQuestionnaires(
                this.selectedQuestions,
                this.selectedCategories,
                () => {
                    this.showExportModal = false;
                }
            );
        },
        
        updateRemoveButtons(questionId) {
            this.removeQuestionButtons = [
                {
                    label: 'Да, в текущем опроснике',
                    class: 'btn-red',
                    action: () => {
                        this.removeQuestion(questionId);
                    }
                },
                {
                    label: 'Да, для всех опросников',
                    class: 'btn-red',
                    action: () => {
                        this.removeQuestion(questionId, 'all');
                    }
                },
                {
                    label: 'Отмена',
                    action: () => {
                        this.showRemoveQuestionModal = false;
                    }
                },
            ];
        },
    },
};
</script>
