<template src="./templates/tree-select.html"></template>
<script>

import Treeselect from 'vue3-treeselect';
import 'vue3-treeselect/dist/vue3-treeselect.css';

// https://vue-treeselect.js.org/#more-features

export default {
    name: 'FormSelect',
    components: {
        Treeselect
    },
    props: {
        value: {
            type: Array,
            default() {
                return null;
            }
        },
        options: {
            type: Array,
            default() {
                return [
                    {
                        id: 'a',
                        label: 'al',
                        children: [
                            {
                                id: 'aa',
                                label: 'aa',
                            },
                            {
                                id: 'ab',
                                label: 'ab',
                            }
                        ]
                    },
                    {
                        id: 'b',
                        label: 'b',
                    },
                    {
                        id: 'c',
                        label: 'c',
                    }
                ];
            },
        },
        multiple: {
            type: Boolean,
            default() {
                return true;
            }
        },
        disableBranchNodes: {
            type: Boolean,
            default() {
                return true;
            }
        },
        placeholder: {
            type: String,
            default() {
                return 'Выберите элементы';
            }
        },
    },
    emits: ['update:value'],
    data() {
      return {
        selected: null,
      };
    },
    watch: {
        selected() {
            this.$emit('update:value', this.selected);
        }
    },
    mounted() {
        this.selected = this.value;
    }
};
</script>
