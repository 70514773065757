<Modal
    v-if="showModal && categories"
    @onClose="onClose"
    @onOk="onOk"
    :buttons="[
                // {
                //     label: 'Закрыть',
                //     event: 'onClose',
                // },
                // {
                //     label: 'OK',
                //     event: 'onOk',
                // },
                // {
                //     label: 'Debug',
                //     event: 'onDebug',
                // },
            ]"
>
    <template v-slot:body>
        <form id="question-edit" action="#">
            <div class="modal-section">
                <div class="form-row m-0">
                    <div class="col">
                        <h2>Выберите категорию</h2>
                    </div>
                </div>
                <div class="form-row m-0">
                    <div class="col">
                        <tree-select v-model:value="categoriesToSelect" :options="options" placeholder="Выберите..." />
                    </div>
                </div>
                <div class="form-row m-0">
                    <div class="col">
                        <form-button class="btn btn-blue mt-1 mr-1" label="Применить" @click.stop.prevent="onOk" />
                        <form-button class="btn btn-blue mt-1" label="Отмена" @click.stop.prevent="onClose" />
                    </div>
                </div>
            </div>
        </form>
    </template>
</Modal>
