<template src="../templates/ExportQuestionsForm.html"></template>
<script>

import Modal from '@c/Modal';
import FormButton from '@f/Button';
import TreeSelect from '@f/TreeSelect';
import {getQuestionnaireCategoryFeed} from '@api/questionnaire';

export default {
    name: 'ExportQuestionsForm',
    components: {
        Modal,
        FormButton,
        TreeSelect,
    },
    props: {
        selectedCategories: {
            type: Array,
            default() {
                return [];
            }
        },
        showModal: {
            type: Boolean,
            default() {
                return false;
            }
        },
        onClose: {
            type: Function,
            default() {}
        },
        onOk: {
            type: Function,
            default() {}
        },
    },
    emits: ['update:selectedCategories'],
    data() {
        return {
            categoriesToSelect: [],
            categories: null,
        };

    },
    computed: {
        options() {
            const tree = [];
            this.categories.forEach(category => {
                const questionnaires = [];
                if (category.questionnaires.length > 0) {
                    category.questionnaires.forEach(questionnaire => {
                        questionnaires.push({id: questionnaire.id, label: questionnaire.name});
                    });
                }
                tree.push({id: category.id, label: category.name, children: questionnaires});
            });
            
            return tree;
        }
    },
    watch: {
        categoriesToSelect() {
            this.$emit('update:selectedCategories', this.categoriesToSelect);
        }
    },
    async mounted() {
        const tree = await getQuestionnaireCategoryFeed({
            categoryType: 5507 // техника
        });
        this.categories = tree.categories;
        
        this.categoriesToSelect = [...this.selectedCategories];
    },
};
</script>
