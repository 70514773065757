export const QUESTION_TYPE_QUESTION = 'QUESTION';
export const QUESTION_TYPE_ANSWER = 'ANSWER';

export default {
    external_id: null,
    question_code: null,
    item_type: QUESTION_TYPE_ANSWER,
    description: '',
    description_orig: '',
    comment: '',
    statement: '',
    value_name: '',
    value_type: '',
    value: '0',
    important: '0',
    formula: '',
    url: '',
    multiple: 0,
    adapter: 0,
    target: 0,
    additional: 0,
    published: 1,
    order: 0,
    details: {
        question_category: '',
        short_question: '',
        question_categories: [],
        question_codes_1c: [],
        answer_codes_1c: [],
    },
    answers: [],
};
