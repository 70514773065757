<PageHeader>
    <div class="row c">
        <Breadcrumbs :items="[{title:'Опросник'},{title:'Техника',href:'/questionnaire/categories/edit'}]"/>
    </div>
</PageHeader>

<PageTitle v-bind:title="questionsCaption"
           :params="{subtitle: (questionsCode ? `Номер анкеты из 1С: ${questionsCode}` : '')}"/>

<div class="row c mt-4">

    <div class="form-row">
        <div class="col mr-2">
            <FormInput
                    :attributes="{placeholder: 'Поиск по вопросам опросника', class: ['input-fluid']}"
            />
        </div>
        <div class="col-auto">
            <div class="form-group-inline">
                <FormCheckbox
                        label="Опубликованы"
                        wrapper-class="inline"
                />
                <FormCheckbox
                        label="Влияют на подбор"
                        wrapper-class="inline"
                />
                <FormButton
                        label="Добавить вопрос"
                        :attributes="{class:['btn-blue', 'h36', 'inline', 'ml-40']}"
                        @click="editQuestion(null)"
                />
            </div>
        </div>
    </div>
    <form-button label="Выбрать все" class="mr-1" @click.stop="selectAll" />
    <form-button label="Применить вопрос" class="btn btn-blue" @click="showExportWindow" />
    <export-questions-form
        v-model:selected-categories="selectedCategories"
        :show-modal="showExportModal"
        :on-close="() => { showExportModal=false;}"
        :on-ok="exportQuestions"
    />
    <Grid
            :columns="[
                    {
                        name: 'id',
                        hidden: true,
                    },
                    {
                        slot_name: 'checkForExport',
                        width: 'minmax(200px, 1fr)',
                    },
                    {
                        name: 'description',
                        label: 'Название',
                        width: 'minmax(150px, 3.33fr)',
                    },
                    {
                        name: 'order',
                        label: 'Вес вопроса',
                        width: 'minmax(50px, 0.40fr)',
                    },
                    {
                        name: 'selection',
                        label: 'Подбор',
                        width: 'minmax(50px, 0.40fr)',
                    },
                    {
                        name: 'published',
                        label: 'Опубликован',
                        width: 'minmax(50px, 0.40fr)',
                    },
                    {
                        name: 'actions',
                        label: 'Действия',
                        width: 'minmax(140px, 0.70fr)',
                    },
                ]"
            :data="questions"
            :onEdit="editQuestion"
            :onRemove="removeQuestionHandler"
    >
        <template v-slot:checkForExport="row">
            <FormCheckbox v-model="selectedQuestions" :value="row.data.id" />
        </template>
    </Grid>
    <FormQuestion
            :showModal="showQuestionModal"
            :onClose="hideQuestionModal"
            :onOk="saveQuestion"
            :question="editedQuestion"
            :get-answers="getAnswersBy1CCode"
    ></FormQuestion>
    <confirm
        v-model:show-modal="showRemoveQuestionModal"
        title="Вы уверены, что хотите удалить вопрос?"
        :buttons="removeQuestionButtons"
    />
</div>
