<Modal
    v-if="showModal"
    @onClose="onClose"
    @onOk="onOk"
    @onDebug="debugForm"
    :buttons="[
                // {
                //     label: 'Закрыть',
                //     event: 'onClose',
                // },
                // {
                //     label: 'OK',
                //     event: 'onOk',
                // },
                // {
                //     label: 'Debug',
                //     event: 'onDebug',
                // },
            ]"
>
    <template v-slot:body>
        <form id="question-edit" action="#" @submit.prevent="showModal = false">

            <div class="modal-section">
                <div class="form-row m-0">
                    <div class="form-row-add">
                        <img src="/img/icons/svg/help-circle.svg" style="width: 20px; height: 20px;">
                    </div>
                    <div class="col">
                        <FormTextarea
                            :attributes="{
                                placeholder: q.id ? 'Заголовок вопроса' : 'Новый вопрос',
                                name: 'description',
                                class: 'input-head'
                            }"
                            v-model="q.description"
                            flexible
                        />
                        <p v-if="q.id" class="text-muted">Q-{{ q.id.toString().padStart(5, '0') }}</p>
                        <p v-if="q.description !== q.description_orig && q.question_code"
                            class="text-muted">
                            Заголовок из 1C:
                            {{ q.description_orig }}
                        </p>
                    </div>
                </div>
                <div v-if="q.description !== q.description_orig && q.question_code"
                    class="form-row offset-form-row-add">
                    <div class="col">
                        <p class="form-title-description-13">
                            Заголовок из 1C:
                            {{ q.description_orig }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="modal-section modal-section-no-b">

                <div class="form-row">
                    <div class="col">
                        <FormCheckbox
                            label="Обязательный"
                            :attributes="{name: 'required'}"
                            v-model="q.required"
                            wrapper-class="inline"
                        />
                    </div>
                </div>
                <div class="form-row">
                    <div class="col">
                        <FormSelect
                            :attributes="{name: 'details.question_category'}"
                            label="Группa вопросов"
                            :options="q.details.question_categories"
                            v-model="q.details.question_category"
                        />
                    </div>
                </div>
                <div class="form-row">
                    <div class="col">
                        <FormInput
                            label="Заголовок для short-list"
                            :attributes="{name: 'details.short_question'}"
                            v-model="q.details.short_question"
                        />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-2">
                        <FormInput
                            label="Вес"
                            :attributes="{name: 'order'}"
                            :value="q.order"
                            v-model="q.order"
                        />

                    </div>
                    <div class="col-2">
                    </div>
                    <div class="col-2 offset-6">
                        <div :class="'form-group'">
                            <label class="h6">Номер из 1С</label>
                            <Multiselect
                                v-model="q.question_code"
                                :options="questionCodes"
                                :canDeselect="false"
                                :loading="isAnswersLoading"
                                @select="handle1CCodeChange"
                            />
                        </div>
                    </div>
                </div>

                <div class="form-row">
                    <div class="col">
                        <FormCheckbox
                            label="Видимость вопроса для клиента"
                            :attributes="{name: 'client_visibility'}"
                            :modelValue="parseInt(q.details.client_visibility)"
                            @change="event => question.details.client_visibility = + event.target.checked"
                            wrapper-class="inline"
                        />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col">
                        <div class="form-group">
                            <label class="h6">Примечание</label>
                            <Editor
                                :api-key="tinyMCEApiKey"
                                :init="{
                                            menubar: false,
                                            relative_urls: true,
                                            convert_urls: false,
                                            statusbar: false,
                                            plugins: [
                                               'advlist autolink lists link image charmap print preview anchor',
                                               'media table paste'
                                            ],
                                            toolbar:
                                                'undo redo | bold italic backcolor | \
                                                alignleft aligncenter alignright alignjustify | \
                                                bullist numlist outdent indent link'
                                        }"
                                label="Примечание"
                                v-model="q.comment"
                            />
                        </div>
                    </div>
                </div>

				<div class="form-row">
					<div class="col">
						<div class="form-group">
							<FormInput
									label="Имя переменной"
									:attributes="{name: 'value_name'}"
									:value="q.value_name"
									v-model="q.value_name"
							/>
						</div>
					</div>
				</div>
            </div>

            <div class="modal-section">

                <div class="form-row">
                    <div class="col">
                        <!-- TODO: добавить компонент Input -> Radio -->
                        <div class="form-group">
                            <label class="h6">Варианты ответов</label>
                            <div>
<!--                                <FormRadio-->
<!--                                    label="Одиночный выбор"-->
<!--                                    :attributes="{name: 'multiple'}"-->
<!--                                    wrapper-class="inline"-->
<!--                                    :modelValue="q.multiple"-->
<!--                                />-->
<!--                                <FormRadio-->
<!--                                    label="Множественный выбор"-->
<!--                                    :attributes="{name: 'multiple'}"-->
<!--                                    wrapper-class="inline"-->
<!--                                    :modelValue="q.multiple"-->
<!--                                />-->
                                <div class="form-check inline">
                                    <input
                                        type="radio"
                                        id="questionTypeSingle"
                                        name="multiple"
                                        value="0"
                                        :style="{appearance: 'radio'}"
                                        v-model="q.multiple"
                                        class="m-0"
                                    />
                                    <label class="m-0" for="questionTypeSingle">Одиночный выбор</label>
                                </div>
                                <div class="form-check inline">
                                    <input
                                        type="radio"
                                        id="questionTypeMultiple"
                                        name="multiple"
                                        value="1"
                                        :style="{appearance: 'radio'}"
                                        v-model="q.multiple"
                                        class="m-0"
                                    />
                                    <label class="m-0" for="questionTypeMultiple">Множественный выбор</label>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div
                class="modal-section modal-section-secondary"
                v-for="(answer, index) in q.answers"
                :key="`answer_${index}`"
            >
                <div class="form-row">
                    <div class="col">
                        <label class="h4">Вариант ответа № {{ index + 1 }}</label>
                        <p
                            v-if="answer.id"
                            style="color: rgba(55, 53, 47, 0.5)"
                        >
                            &nbsp;A-{{ answer.id.toString().padStart(5, '0') }}
                        </p>
                        <div
                            v-if="q.answers[index].description !== q.answers[index].description_orig && q.question_code">
                            <span
                                style="color: rgba(55, 53, 47, 0.5)"
                            >
                                Заголовок из 1C: {{ q.answers[index].description_orig }}
                            </span>
                        </div>
                    </div>
                    <div class="col">
                        <FormSelect
                            v-if="!q.question_code"
                            :attributes="{name: `answers[${index}][question_code]`}"
                            label="Номер ответа из 1С"
                            :options="answerCodes"
                            v-model="q.answers[index].question_code"
                        />
                    </div>
                </div>
                <div class="form-row">
                    <div class="col">
                        <FormInput
                            :key="`description_${index}`"
                            :attributes="{
                                name: `answers[${index}][description]`,
                                id: `description_${index}`,
                                disabled: !!q.question_code
                            }"
                            v-model="q.answers[index].description"
                        />
                    </div>
                </div>
                <div class="form-row">
                    <div class="col">
                        <div class="form-group">
                            <label class="h6">Продающая часть при выборе ответа (обоснование на ответ)</label>
                            <Editor
                                :api-key="tinyMCEApiKey"
                                :init="{
                                    menubar: false,
                                    relative_urls: true,
                                    convert_urls: false,
                                    statusbar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'media table paste'
                                    ],
                                    toolbar:
                                        'link undo redo | bold italic backcolor | \
                                        alignleft aligncenter alignright alignjustify | \
                                        bullist numlist outdent indent link'
                                }"
                                label="Продающая часть при выборе ответа (обоснование на ответ)"
                                v-model="q.answers[index].comment"
                            />
                        </div>
                    </div>
                </div>

                <div class="form-row">
                    <div class="col">
                        <label class="h6">Текст выгоды в оферте</label>
                        <FormInput
                            :key="`statement_${index}`"
                            :attributes="{name: `answers[${index}][statement]`, id: `statement_${index}`}"
                            v-model="q.answers[index].statement"
                        />
                    </div>
                </div>

				<div class="form-row">
					<div class="col">
						<div class="form-group">
							<FormInput
									label="Значение переменной"
									:attributes="{name: 'value'}"
									:value="q.answers[index].value"
									v-model="q.answers[index].value"
							/>
						</div>
					</div>
				</div>

                <div class="form-row">
                    <div class="col">
                        <FormButton
                            v-if="!q.question_code"
                            label="Удалить вариант"
                            :attributes="{class:['btn-red', 'p-4-8']}"
                            @click.prevent="remove(index)"
                        />
                    </div>
                </div>
            </div>

            <div class="modal-section modal-section-no-b">
                <div class="form-row">
                    <div class="col">
                        <FormButton
                            v-if="!q.question_code"
                            label="Добавить вариант ответа"
                            :attributes="{class:['btn-blue', 'p-4-8', 'no-bg']}"
                            @click.prevent="add()"
                        />
                    </div>
                </div>
            </div>

            <div class="modal-section">
                <div class="form-row">
                    <div class="col">
                        <div class="form-group-inline">
                            <FormButton
                                label="Сохранить"
                                :attributes="{class:['btn-green', 'h36', 'inline']}"
                                @click="onOk"
                            />
                        </div>
                        <div class="form-group-inline">
                            <FormCheckbox
                                label="Опубликовано"
                                :attributes="{name: 'published'}"
                                v-model="q.published"
                                wrapper-class="inline"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </template>
</Modal>
